/**
 * addPaymentMethods Api is used to Add Payment Method to users account.
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import axios from "axios";
import * as actions from "./actionTypes";
import {toastr} from "react-redux-toastr";
import {useNavigate} from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

/**
 * ADD Payment Method adds a payment Method to users account.
 * @endpoint "payment-methods/addPaymentMethod"  Middleware customization and documentation.
 * @param id Customer Login userID to get user specific details.
 * @param cardNumber Customer cardNumber to add user specific details.
 * @param cardExpiry Customer cardExpiry to add user specific details.
 * @param cardCvv Customer cardCvv to add user specific details.
 *
 * @return JSON Payment Methods List Object .
 */
const addPaymentMethod = (id,last4,expiry,dataDescriptor,dataValue,cardType) => async (dispatch, getState) => {

    const data = {
        paymentMethodId:"authorize_net_cim_credit_card",
        cardExpiry:expiry,
        cardDataValue:dataValue,
        cardDataDescriptor:dataDescriptor,
        cardLast4:last4,
        cardTokenizePayment:"true",
        cardHttpReferer:"/my-account/add-payment-method/",
        cardAddPayment:"1",
        cardType: cardType
    }

    const config = {
        params: {
            customerId: id
        }
    }

    dispatch({type: actions.ADD_PAYMENT_METHOD_REQUEST})
    try{
        const response = await axios.post('/paymentmethods/addpaymentmethod',data,config)

        dispatch({type: actions.ADD_PAYMENT_METHOD_SUCCESS, payload: response.data})
        const { success } = response.data;

        if(success === true){
            // toastr.success('Successfully Added', 'Added a payment method');
            toast.success('Successfully Added a payment method');
            navigate('/payment-methods');
        }else if(success === false){
            const {message} = response.data;
            const displayError = message.split('Invalid');
            const errorLog = displayError[1];

            // toastr.error('Invalid' +errorLog +' please try again');
            toast.error('Invalid' +errorLog +' please try again');
        }else{
            // toastr.error('Failed to add a card, please try again!');
            toast.error('Failed to add a card, please try again!');
        }
    }catch(error){
        dispatch({type: actions.ADD_PAYMENT_METHOD_FAILURE, error});
    }
}

export default addPaymentMethod
