// import "../../styles/style.scss";
import MUIDataTable, {ExpandButton} from "mui-datatables";
import {ThemeProvider} from "@mui/material/styles";
import {CacheProvider} from "@emotion/react";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import createCache from "@emotion/cache";
import {createTheme} from "@mui/material/styles";
import {TableCell, TableRow} from "@mui/material";
import { Paper} from "@mui/material";
import { relatedBillingDetails } from "../orderList/ordersList";
import './subcriptionsList.css';
import formatCurrency from "../../functions/formatCurrency";
import parse from 'html-react-parser';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';
import generateSortableColumn from "../../components/functions/generateSortableColumn";
import {RelatedOrders} from "../orderList/ordersUtils";

import {MyExpansionChip,MyChip,getStatusIcon,statusIconMap,getStatusColor,statusColorMap,useStyles} from "../orderList/ordersList";

const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});

export default function SubscriptionsList(props){

    const subscriptionList = props.subList;
    const orderList = props.orders;

    const [rows, setRows] = useState([]);
    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('none');

    useEffect(() => {
        const storedExpandedRows = sessionStorage.getItem("expandedSubscriptionRows");

        if (storedExpandedRows) {
            setRows(JSON.parse(storedExpandedRows));
        }
    }, []);

    const getFixedWidthProps = (width) => {
        return () => ({
            style: { width, maxWidth: width, minWidth: width }
        });
    };

    const data = subscriptionList && subscriptionList.map(item=>{
        let sub = "";
        let quantity = 0;
        const {id,next_payment_date_gmt, line_items,billing:{company},payment_url,needs_payment} = item;
        const date = new Date(next_payment_date_gmt.toString()).toLocaleDateString();
        const itemTotal = formatCurrency(item.total);

        line_items.map((e)=>{
            sub = sub !== "" ? sub + " & " +e.name : e.name;
            quantity = quantity + 1;
            return sub;
        })

        const paymentURL = () => {

            const payOrderCheckout = () => {
                window.location.assign(payment_url);
            }

            return payment_url !== '' && needs_payment ? (
                <div className={"actionButtons"}>
                    {/*<button onClick={payOrderCheckout} className={"wooButtons"}>*/}
                    {/*    Pay*/}
                    {/*</button>*/}
                    <Link to={"/view-subscription/" + id}>
                        <button className={"wooButtons"}>
                            View
                        </button>
                    </Link>
                </div>) : (
                <div className={"actionButtons"}>

                    <Link to={"/view-subscription/"+id}>

                        <button className={"wooButtons"}>
                            View
                        </button>
                    </Link>
                </div>
            )
        }

        return [item.id,sub,company,date,item.status.replace(/-/g, "").toUpperCase(),itemTotal,paymentURL]
    });

    const subStatusColorMap = {
        ACTIVE: '#c6e1c6',
        EXPIRED: '#bd94ae',
        PENDING: '#c6e1c6',
        ONHOLD: '#f8dda7',
        CANCELLED: '#e5e5e5'
    };

    const getSubStatusColor = (status) => {
        return subStatusColorMap[status] || 'black'; // return black as default color if status not in map
    };

    const subStatusIconMap = {
        ACTIVE: VerifiedIcon,
        PENDING: AccessTimeFilledIcon,
        EXPIRED: HighlightOffIcon,
        ONHOLD: RemoveCircleIcon,
        CANCELLED: CancelIcon,
    }

    const getSubStatusIcon = (status) => {
        return subStatusIconMap[status] || AcUnitIcon;
    }

    const columns = [
        generateSortableColumn("Subscription ID", 0, { filter: false, sortThirdClickReset: true, sortDescFirst: true, setCellProps: getFixedWidthProps('10.00%') }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Service(s)", 1, {
            filter: true,
            sortThirdClickReset: true,
            sortDescFirst: true,
            setCellProps: () => ({
                style: {
                    ...getFixedWidthProps('18.00%').style,
                    textAlign:'left'
                }
            }),
            customBodyRender: (value) => (
                value.split("\n").map((item, index) => (
                    <div key={index}>
                        {item}
                        {index !== value.split("\n").length - 1 && <br />}
                    </div>
                ))
            )
        }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Company", 2, { filter: true, sortThirdClickReset: true,sortDescFirst: true, setCellProps: getFixedWidthProps('17.00%') }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Renewal Date", 3, { filter: true, sortThirdClickReset: true,sortDescFirst: true,setCellProps: getFixedWidthProps('15.00%') }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Status", 4, {
            filter: false,
            sortThirdClickReset: true,
            sortDescFirst: true,
            setCellProps: getFixedWidthProps('15.00%'),
            customBodyRender: (value) => {
                const color = getSubStatusColor(value);
                const icon = getSubStatusIcon(value);
                return (
                    <span className={"statusIcon"}>
                    <MyChip label={value} icon={icon} color={color}/>
                </span>
                );
            }
        }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),  // Even though it's not sortable, we still pass the required state to ensure consistent appearance.
        generateSortableColumn("Total", 5, { filter: false, sortThirdClickReset: true, sortDescFirst: true, setCellProps: getFixedWidthProps('10.00%') }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Actions", 6, { filter: false, sortThirdClickReset: true, setCellProps: getFixedWidthProps('15.00%') }, false, sortedColumn, setSortedColumn, sortDirection, setSortDirection)
    ];

    const theme = createTheme({
        overrides: {
            MUIDataTableSelectCell: {
                expandDisabled: {
                    // Soft hide the button.
                    visibility: "hidden"
                }
            }
        }
    });

    const options = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        selectableRows: "none",
        fixedHeader: true,
        fixedSelectColumn: true,
        selectableRowsOnClick: false,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        rowsExpanded: rows,
        rowsPerPageOptions: [10, 15, 20 , 25, 30],
        onTableInit: () => {
            const storedExpandedRows = sessionStorage.getItem("expandedSubscriptionRows");
            if (storedExpandedRows) {
                setRows(JSON.parse(storedExpandedRows));
            }
        },
        setRowProps: (row, dataIndex, rowIndex) => {
            if(rowIndex % 2 === 0 ){
                return {
                    style: {
                        backgroundColor: 'white',
                        fontSize: '18px'
                    },
                }
            }
            else{
                return{
                    style: {
                        backgroundColor: '#f0f0f1',
                        fontSize: '18px'
                    }
                }
            }
        },
        renderExpandableRow: (rowData, rowMeta) => {

            const colSpan = rowData.length + 1;

            return subscriptionList && subscriptionList.map((item) =>{
                const { id,status,date_created,payment_method_title, line_items, parent_id,relatedOrders,
                    billing: {company,first_name,last_name,address_1,address_2,phone,email,state,city,postcode}} = item;
                const dateForSub = new Date(date_created.toString()).toLocaleDateString();

                return (rowData[0] === id) ? (
                    <TableRow>
                        <TableCell colSpan={colSpan}>
                            <Paper className={"paperAddress"}>
                                <div className={"order-sub-details-address"}>
                                    {line_items.map((i) => {
                                        const {name, meta_data} = i;

                                        return (
                                            <div className={"orderMetaData"}>
                                                <div className={"serviceName"}>
                                                    <Link to={"/view-subscription/"+id} style={{color: '#536889'}}>
                                                        <Typography variant="h5" style={{fontWeight: 600}} className={"idNamelink"}>{id}-{name}</Typography><br/>
                                                        {/*<h6 className={"idNamelink"}>{id}-{name}</h6>*/}
                                                    </Link>
                                                </div>
                                                <div className={"serviceMetaData"}>
                                                    <ul className={"metaDataList"}>
                                                        {meta_data.filter(({ key }) => key.charAt(0) !== "_").slice(0,5).map((c)=>{
                                                            let {key,display_key, value} = c;

                                                            return key.charAt(0) !== "_" ? (
                                                                <li className={"metadataTitle"}>
                                                                        <span>
                                                                            <strong>{display_key}</strong>
                                                                            <br/>
                                                                            {parse(value)}
                                                                            {/*<p><br/></p>*/}
                                                                        </span>
                                                                </li>
                                                            ) : (
                                                                <div hidden="hidden">
                                                                    Sorry
                                                                </div>
                                                            )
                                                        })}

                                                        {[...Array(Math.max(0, 5 - meta_data.filter(({ key }) => key.charAt(0) !== "_").length))].map((_, i) => (
                                                            <li className={"metadataTitle"} key={`placeholder-${i}`}>
                                                                <span>&nbsp;</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    })}

                                    <RelatedOrders
                                        orderList={orderList}
                                        relatedOrders={relatedOrders}
                                    />

                                    {relatedBillingDetails(first_name,last_name,company,
                                        address_1,address_2,city,state,postcode,email,phone)}
                                </div>
                            </Paper>
                        </TableCell>
                    </TableRow>
                ) : (
                    <div hidden="hidden">

                    </div>
                )
            })

        },
        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
            const expandedRowIndexes = allExpanded.map(row => row.dataIndex);
            sessionStorage.setItem('expandedSubscriptionRows', JSON.stringify(expandedRowIndexes));
        },
    };

    return  (
        // Inside the main component
        <div className={"pageView"}>
            <div className={"pageWrapper"}>
                <Typography variant="h4" className={"pageTitle"}>My Subscriptions List</Typography>
                {/*<h1 className={"pageTitle"}>My Subscriptions List</h1>*/}
                <CacheProvider value={muiCache}>
                    {/*<ThemeProvider theme={theme}>*/}
                        <MUIDataTable
                            className="customTableHeader"
                            title={""}
                            data={data}
                            columns={columns}
                            options={options}
                            style={{ tableLayout: 'fixed' }}
                            // components={components}
                        />
                    {/*</ThemeProvider>*/}
                </CacheProvider>
            </div>
        </div>
    );
}
