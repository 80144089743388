/**
 * ActionTypes defined for easy changes at one place
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

/**
 * Action Types for Editing user details
 */
export const EDIT_CUSTOMER_REQUEST = 'EDIT_CUSTOMER_REQUEST'
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS'
export const EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE'

/**
 * Action Types for Editing user billing details
 */
export const EDIT_BILLING_ADDRESS_REQUEST = 'EDIT_BILLING_ADDRESS_REQUEST'
export const EDIT_BILLING_ADDRESS_SUCCESS = 'EDIT_BILLING_ADDRESS_SUCCESS'
export const EDIT_BILLING_ADDRESS_FAILURE = 'EDIT_BILLING_ADDRESS_FAILURE'

/**
 * Action Types for Editing user shipping details
 */
export const EDIT_SHIPPING_ADDRESS_REQUEST = 'EDIT_SHIPPING_ADDRESS_REQUEST'
export const EDIT_SHIPPING_ADDRESS_SUCCESS = 'EDIT_SHIPPING_ADDRESS_SUCCESS'
export const EDIT_SHIPPING_ADDRESS_FAILURE = 'EDIT_SHIPPING_ADDRESS_FAILURE'

/**
 * Action Types for Getting payment methods details
 */
export const GET_PAYMENT_METHODS_REQUEST = 'GET_PAYMENT_METHODS_REQUEST'
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS'
export const GET_PAYMENT_METHODS_FAILURE = 'GET_PAYMENT_METHODS_FAILURE'


/**
 * Action Types for Making Default payment methods details
 */
export const GET_MAKE_DEFAULT_REQUEST = 'GET_MAKE_DEFAULT_REQUEST'
export const GET_MAKE_DEFAULT_SUCCESS = 'GET_MAKE_DEFAULT_SUCCESS'
export const GET_MAKE_DEFAULT_FAILURE = 'GET_MAKE_DEFAULT_FAILURE'

/**
 * Action Types for Making Default payment methods details
 */
export const GET_UPDATE_SUBSCRIPTIONS_REQUEST = 'GET_UPDATE_SUBSCRIPTIONS_REQUEST'
export const GET_UPDATE_SUBSCRIPTIONS_SUCCESS = 'GET_UPDATE_SUBSCRIPTIONS_SUCCESS'
export const GET_UPDATE_SUBSCRIPTIONS_FAILURE = 'GET_UPDATE_SUBSCRIPTIONS_FAILURE'


/**
 * Action Types for Delete payment methods details
 */
export const DELETE_PAYMENT_METHOD_REQUEST = 'DELETE_PAYMENT_METHOD_REQUEST'
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS'
export const DELETE_PAYMENT_METHOD_FAILURE = 'DELETE_PAYMENT_METHOD_FAILURE'

/**
 * Action Types for Adding payment methods details
 */
export const ADD_PAYMENT_METHOD_REQUEST = 'ADD_PAYMENT_METHOD_REQUEST'
export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS'
export const ADD_PAYMENT_METHOD_FAILURE = 'ADD_PAYMENT_METHOD_FAILURE'

/**
 * Action Types for Getting user details
 */
export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST'
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE'

/**
 * Action Types for Getting user orders list
 */
export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE'

/**
 * Action Types for Getting user subscriptions list details
 */
export const GET_SUBSCRIPTION_REQUEST = 'GET_SUBSCRIPTION_REQUEST'
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS'
export const GET_SUBSCRIPTION_FAILURE = 'GET_SUBSCRIPTION_FAILURE'

/**
 * Action Types for Getting user products list details
 */
export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE'

/**
 * Action Types for Getting user products list details
 */
export const GET_HELP_DATA_REQUEST = 'GET_HELP_DATA_REQUEST'
export const GET_HELP_DATA_SUCCESS = 'GET_HELP_DATA_SUCCESS'
export const GET_HELP_DATA_FAILURE = 'GET_HELP_DATA_FAILURE'

/**
 * Action Types for Getting user products list details
 */
export const GET_NONCED_URL_REQUEST = 'GET_NONCED_URL_REQUEST'
export const GET_NONCED_URL_SUCCESS = 'GET_NONCED_URL_SUCCESS'
export const GET_NONCED_URL_FAILURE = 'GET_NONCED_URL_FAILURE'

/**
 * Action Types for Setting expanded row states for orders & subscriptions list details
 */
export const SET_SUBSCRIPTIONS_ROWS = 'SET_SUBSCRIPTIONS_ROWS'
export const SET_ORDER_ROWS = 'SET_ORDER_ROWS'

/**
 * Action Types for Getting switch back url request
 */
export const GET_SWITCH_BACK_URL_REQUEST = 'GET_SWITCH_BACK_URL_REQUEST'
export const GET_SWITCH_BACK_URL_SUCCESS = 'GET_SWITCH_BACK_URL_SUCCESS'
export const GET_SWITCH_BACK_URL_FAILURE = 'GET_SWITCH_BACK_URL_FAILURE'


/**
 * Action Types for making Accept.js call to authorizeNet
 */
export const POST_ACCEPT_JS_REQUEST = 'POST_ACCEPT_JS_REQUEST'
export const POST_ACCEPT_JS_SUCCESS = 'POST_ACCEPT_JS_SUCCESS'
export const POST_ACCEPT_JS_FAILURE = 'POST_ACCEPT_JS_FAILURE'

/**
 * Action Types for making Change Payment Method call to wordpress/woocommerce
 */
export const CHANGE_PAYMENT_METHOD_FOR_ALL_SUBS_REQUEST = 'CHANGE_PAYMENT_METHOD_FOR_ALL_SUBS_REQUEST'
export const CHANGE_PAYMENT_METHOD_FOR_ALL_SUBS_SUCCESS = 'CHANGE_PAYMENT_METHOD_FOR_ALL_SUBS_SUCCESS'
export const CHANGE_PAYMENT_METHOD_FOR_ALL_SUBS_FAILURE = 'CHANGE_PAYMENT_METHOD_FOR_ALL_SUBS_FAILURE'

/**
 * Action Types for making Getting Companies call to wordpress/woocommerce
 */
export const GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST'
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS'
export const GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE'

/**
 * Action Types for making DELETE Company call to wordpress
 */
export const DELETE_COMPANIES_REQUEST = 'DELETE_COMPANIES_REQUEST'
export const DELETE_COMPANIES_SUCCESS = 'DELETE_COMPANIES_SUCCESS'
export const DELETE_COMPANIES_FAILURE = 'DELETE_COMPANIES_FAILURE'

/**
 * Action Types for making ADD Company call to wordpress
 */
export const ADD_COMPANIES_REQUEST = 'ADD_COMPANIES_REQUEST'
export const ADD_COMPANIES_SUCCESS = 'ADD_COMPANIES_SUCCESS'
export const ADD_COMPANIES_FAILURE = 'ADD_COMPANIES_FAILURE'