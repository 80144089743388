import React from "react";
import { Box, Typography } from '@mui/material';
import {Link} from "react-router-dom";
import { styled } from '@mui/system';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import InventoryIcon from '@mui/icons-material/Inventory';
import PendingIcon from '@mui/icons-material/Pending';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ErrorIcon from '@mui/icons-material/Error';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const Chip = styled(Box)(({ theme, backgroundColor }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: "6px 15px",
    borderRadius: '20px', // Change as needed
    color: "white",
    backgroundColor: backgroundColor,
    '& .MuiTypography-root': {
        marginRight: theme.spacing(1), // Adjust spacing as needed
        fontWeight: "600 !important",
        letterSpacing: "0.0938em !important",
    },
}));

export const SubChip = styled(Chip)({
    padding: '2px 15px',
    '& .MuiTypography-root': {
        fontSize: '0.8em',
    },
});

export const statusColorMap = {
    ACTIVE: '#c6e1c6',
    PENDING: '#c6e1c6',
    OABANNED: '#dd3333',
    INACTIVE: '#dd3333',
    PROCESSING: '#c6e1c6',
    INPROGRESS: '#6d98bf',
    COMPLETED: '#c8d7e1',
    ONHOLD: '#f8dda7',
    CANCELLED: '#e5e5e5',
    REFUNDED: '#e5e5e5',
    FAILED: '#eba3a3',
    EXPIRED: '#bd94ae'
};

export const getStatusColor = (status) => {
    return statusColorMap[status] || 'black'; // return black as default color if status not in map
};

export const statusIconMap = {
    ACTIVE: VerifiedIcon,
    PENDING: AccessTimeFilledIcon,
    OABANNED: NotInterestedIcon,
    INACTIVE: InventoryIcon,
    PROCESSING: PendingIcon,
    INPROGRESS: HourglassTopIcon,
    COMPLETED: CheckCircleIcon,
    ONHOLD: RemoveCircleIcon,
    CANCELLED: CancelIcon,
    REFUNDED: ChangeCircleIcon,
    FAILED: ErrorIcon,
    EXPIRED: HighlightOffIcon
}

export const getStatusIcon = (status) => {
    return statusIconMap[status] || AcUnitIcon;
}

export function MyChip({ label , icon: Icon, color }) {
    return (
        <Chip backgroundColor={color} style={{width: 'fit-content'}}>
            <Typography className="MuiTypography-root">{label}</Typography>
            <Icon />
        </Chip>
    );
}

export function MyExpansionChip({ label , icon: Icon, color }) {
    return (
        <SubChip backgroundColor={color} style={{width: 'fit-content'}}>
            <Typography className="MuiTypography-root">{label}</Typography>
            <Icon />
        </SubChip>
    );
}

export const relatedSubscriptions = (subList,subs) => {

    subs = subs || [];

    return subs !== 'N/A' && subs.length > 0 ? (
        <div className={"relatedSubscriptionsBlock"}>
            <div className={"relatedSubscriptionsHeader"}>
                <Typography variant="h5" style={{fontWeight: 600}}>Related Subscriptions</Typography>
            </div>
            <div className={"relatedSubscriptionsMeta"}>
                <ul className={"relatedSubscriptionsMetaHeader"}>
                    <li>
                        <span><strong>Subscription ID</strong></span>
                    </li>
                    <li>
                        <span><strong>Service(s)</strong></span>
                    </li>
                    <li>
                        <span><strong>Status</strong></span>
                    </li>
                    <li>
                        <span><strong>Renewal Date</strong></span>
                    </li>
                    <li>
                        <span><strong>Payment Method</strong></span>
                    </li>
                </ul>
                {subList.map((item)=>{
                    const {parent_id, id: subId,line_items,status,date_created,next_payment_date_gmt,payment_method_title} = item;
                    const dateForSub = new Date(date_created.toString()).toLocaleDateString();
                    const renewalDateForSub = new Date(next_payment_date_gmt.toString()).toLocaleDateString();

                    const relatedStatus = status.replace(/-/g, "").toUpperCase();
                    const subStatusColor = getStatusColor(relatedStatus);
                    const subIcon = getStatusIcon(relatedStatus);

                    return subs.includes(subId) ? (
                        <ul className={"relatedSubscriptionsMetaHeader"} style={{paddingBottom: '5px'}}>
                            <li>
                                <Link to={"/view-subscription/"+subId} style={{color: '#536889'}}>
                                    <span className={"idNamelink"}>{subId}</span>
                                </Link>
                            </li>
                            <li>
                                {line_items.map((e)=>{
                                    const {name} = e;
                                    return (
                                        <span>{name}<br/></span>
                                    )
                                })}
                            </li>
                            <li>
                                    <span className={"relatedSubStatusCapsule"}>
                                        <MyExpansionChip label={relatedStatus} icon={subIcon} color={subStatusColor}/>
                                    </span>
                            </li>
                            <li>
                                <span>{renewalDateForSub}</span>
                            </li>
                            <li>
                                {payment_method_title}
                            </li>
                        </ul>
                    ) : (
                        <div hidden="hidden">

                        </div>
                    )
                })}
            </div>
        </div>
    ) : (
        <div hidden="hidden">

        </div>
    )
};

export const RelatedOrders = ({ orderList, relatedOrders }) => {
    let relatedOrdersArray = [];

    if (Array.isArray(relatedOrders)) {
        relatedOrdersArray = relatedOrders;
    } else if (typeof relatedOrders === 'number') {
        relatedOrdersArray = [relatedOrders];
    } else if (relatedOrders && typeof relatedOrders === 'object') {
        relatedOrdersArray = Object.keys(relatedOrders).map(key => parseInt(relatedOrders[key]));
    }

    const hasRelatedOrders = relatedOrdersArray.length > 0;

    // Filter the orders that are not created via 'subscription'
    const filteredOrderList = orderList.filter(order => order.created_via !== 'subscription');

    // Prepare a list of related orders by matching IDs
    const relatedOrderDetails = filteredOrderList.filter(order => relatedOrdersArray.includes(parseInt(order.id)));

    // Display the component only if there are related orders
    return relatedOrderDetails.length > 0 ? (
        <div className={"relatedSubscriptionsBlock"}>
            <div className={"relatedSubscriptionsHeader"}>
                <Typography variant="h5" style={{fontWeight: 600}}>Related Orders</Typography><br/>
            </div>
            <div className={"relatedSubscriptionsMeta"}>
                <ul className={"relatedSubscriptionsMetaHeader"}>
                    <li>
                        <span><strong>Order ID</strong></span>
                    </li>
                    <li>
                        <span><strong>Service(s)</strong></span>
                    </li>
                    <li>
                        <span><strong>Status</strong></span>
                    </li>
                    <li>
                        <span><strong>Order Date</strong></span>
                    </li>
                    <li>
                        <span><strong>Payment Method</strong></span>
                    </li>
                </ul>
                {relatedOrderDetails.map((item) => {
                    const {id: orderID, status, payment_method_title, date_created, line_items} = item;
                    const orderDate = new Date(date_created.toString()).toLocaleDateString();
                    const relatedStatus = status.replace(/-/g, "").toUpperCase();
                    const relatedStatusColor = getStatusColor(relatedStatus);
                    const relatedIcon = getStatusIcon(relatedStatus);

                    return (
                        <ul className={"relatedSubscriptionsMetaHeader"} style={{paddingBottom: '5px'}}>
                            <li>
                                <Link to={"/view-order/"+orderID} style={{color: '#536889'}}>
                                    <span className={"idNamelink"}>{orderID}</span>
                                </Link>
                            </li>
                            <li>
                                {line_items.map((e) => (
                                    <span key={e.id}>{e.name}<br/></span>
                                ))}
                            </li>
                            <li>
                                <span className={"relatedSubStatusCapsule"}>
                                    <MyExpansionChip label={relatedStatus} color={relatedStatusColor} icon={relatedIcon}/>
                                </span>
                            </li>
                            <li>
                                <span>{orderDate}</span>
                            </li>
                            <li>
                                <span>{payment_method_title}</span>
                            </li>
                        </ul>
                    );
                })}
            </div>
        </div>
    ) : null;  // If there are no related orders, render nothing
};
