import * as React from 'react';
import { GlobalStyles } from '@mui/system';

const globalBaseline = 8; // px
const spacings = {
    'xxs': 0.5,
    'xs': 1,
    's': 2,
    'm': 3,
    'l': 4,
    'xl': 5,
    'xxl': 6,
};

// Compute your spacings as multiples of the baseline
const computedSpacings = {};
for (const spacingName in spacings) {
    computedSpacings[`--spacing-${spacingName}`] = `${globalBaseline * spacings[spacingName]}px`;
}

// Typography variables
const typography = {
    // Families
    '--font-family-primary': '"proxima-nova", sans-serif',
    '--font-family-secondary': '"freight-text-pro", serif',
    // Weights
    '--font-weight-light': '300',
    '--font-weight-regular': '400',
    '--font-weight-medium': '500',
    '--font-weight-semi-bold': '600',
    '--font-weight-bold': '700',
    '--font-weight-extra-bold': '800',
    // Sizes, line heights, margins
    // Add your sizes here as required
}


export const GlobalCss = () => (
    <GlobalStyles
        styles={{
            ':root': {
                '--global-baseline': `${globalBaseline}px`,
                ...computedSpacings,
                ...typography,
                '--wp-admin-bar': '32px',
                '@media screen and (max-width: 782px)': {
                    '--wp-admin-bar': '46px',
                },
                '--transition-base-easing': 'ease-in-out',
                '--transition-base-duration': '125ms',
                '--font-family-primary': '"proxima-nova", sans-serif',
                '--font-family-secondary': '"freight-text-pro", serif',
                '--font-weight-light': 300,
                '--font-weight-regular': 400,
                '--font-weight-medium': 500,
                '--font-weight-semi-bold': 600,
                '--font-weight-bold': 700,
                '--font-weight-extra-bold': 800,

                '--font-size-body-desktop': '16px',
                '--font-size-body-mobile': '16px',
                '--line-height-body-desktop': 1.45,
                '--line-height-body-mobile': 1.5,

                '--font-size-h1-desktop': '48px',
                '--font-size-h1-mobile': '40px',
                '--line-height-h1-desktop': 1.2,
                '--line-height-h1-mobile': 1.2,
                '--margin-bottom-h1': 'var(--spacing-m)',

                '--font-size-h2-desktop': '38px',
                '--font-size-h2-mobile': '36px',
                '--line-height-h2-desktop': 1.2,
                '--line-height-h2-mobile': 1.2,
                '--margin-bottom-h2': 'var(--spacing-s)',

                '--font-size-h3-desktop': '30px',
                '--font-size-h3-mobile': '26px',
                '--line-height-h3-desktop': 1.2,
                '--line-height-h3-mobile': 1.2,
                '--margin-bottom-h3': '14px',

                '--font-size-h4-desktop': '22px',
                '--font-size-h4-mobile': '20px',
                '--line-height-h4-desktop': 1.2,
                '--line-height-h4-mobile': 1.2,
                '--margin-bottom-h4': 'var(--spacing-xs)',

                '--font-size-h5-desktop': '20px',
                '--font-size-h5-mobile': '18px',
                '--line-height-h5-desktop': 1.2,
                '--line-height-h5-mobile': 1.2,
                '--margin-bottom-h5': 'var(--spacing-xs)',

                '--font-size-h6-desktop': '18px',
                '--font-size-h6-mobile': 'var(--font-size-body-mobile)',
                '--line-height-h6-desktop': 1.2,
                '--line-height-h6-mobile': 1.2,
                '--margin-bottom-h6': 'var(--spacing-xs)',

                '--font-size-sidebar-heading': '28px',
                '--line-height-sidebar-heading': 1,
                '--margin-bottom-sidebar-heading': 'var(--spacing-s)',

                '--font-size-x-small': '12px',
                '--font-size-small': '14px',
                '--font-size-medium': '20px',
                '--font-size-large': '24px',
                '--font-size-x-large': '32px',

                '--font-size-primary-nav-desktop': '18px',
                '--font-size-primary-nav-mobile': '20px',

                '--font-size-footer-widget-title': '16px',
                '--line-height-footer-widget-title': 'normal',

                '--font-size-button': '16px',
                '--line-height-button': 1.167,
                '--grid-width': '1200px',
                '--grid-gutter': '24px',
                '--grid-width-full': 'calc(var(--grid-width) + var(--grid-gutter)*2)',
                '--grid-width-narrow': '920px',
                '--responsive--aligndefault-width': '100%',
                '--responsive--alignwide-width': '100%',
            },
            'body': {
                fontFamily: 'var(--font-family-primary)',
                fontWeight: 'var(--font-weight-regular)',
                fontSize: 'var(--font-size-body-desktop)',
                lineHeight: 'var(--line-height-body-desktop)',
                '--wp--style--block-gap': 'var(--spacing-l)',
                'backgroundColor': 'var(--color-site-background)',
                'color': 'var(--color-brand-pale-blue)',
                margin: '0',
                '@media (min-width: 920px)': {
                    fontSize: 'var(--font-size-body-desktop)',
                    lineHeight: 'var(--line-height-body-desktop)',
                }
            },
            'html': {
                'scrollBehavior': 'smooth',
                boxSizing: 'border-box',
                lineHeight: 1.15, /* normalize.css */
                '-webkit-text-size-adjust': '100%', /* normalize.css */
            },
            '*': {
                boxSizing: 'content-box',
                '&::before, &::after': {
                    boxSizing: 'content-box',
                },
            },
            '.layout': {
                display: 'flex',
                flexDirection: 'column',
            },
            '.container': {
                display: 'flex',
                marginRight: '20px',
                fontFamily: '"proxima-nova", sans-serif',
                color: '#536889',
                height: 'auto',
                width: 'auto',
                fontSize: '16px',
                padding: '40px 0 80px 0',
            },
            '.pageView': {
                flex: 6,
            },
            '.pageWrapper': {
                flex: 1,
                height: '60%',
                marginLeft: '20px',
                color: '#536889',
                fontSize: '1.2rem',
            },
            '.helpPageWrapper': {
                flex: "1",
                height: "60%",
                marginLeft: "20px",
                color: "#536889",
                fontSize: "1.2rem",
                marginRight: "20px",
                maxWidth: "1000px",
            },
            '.loading': {
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            },
            '.pageTitle': {
                fontSize: '24px',
                fontFamily: '"proxima-nova", sans-serif !important',
                color: '#0d2959',
                fontWeight: 600,
                textTransform: 'uppercase',
            },
            '.sidebar': {
                height: 'max-content',
                backgroundColor: '#f0f0f0',
                position: 'relative',
                paddingLeft: '10px',
                paddingRight: '10px',
                margin: '0 20px 20px 0',
                display: 'flex',
                flexDirection: 'column',
            },
            '.sidebarWrapper': {
                padding: '20px 20px 0 20px',
                color: '#555',
            },
            '.sidebarMenu': {
                marginBottom: '10px',
            },
            '.sidebarList': {       //unordered list
                listStyle: 'none',
                padding: '5px',
            },
            '.sidebarListItem': {       //unordered list item
                paddingLeft: '10px',
                paddingRight: '10px',
                alignItems: 'center',
                borderRadius: '10px',
                color: '#0d2959',
                letterSpacing: '2px',
                textTransform: 'uppercase',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '2.5',
                position: 'relative',
                // display: 'flex',
            },
            '.sidebarIcon': {
                marginRight: '5px',
                fontSize: '2rem !important',
            },
            '.toggleSidebar:hover': {
                color: '#f6bb46',
                cursor: 'pointer',
            },
            '.versionNumber': {
                textAlign: 'right',
            },
            '.navLinkStyle': {
                textDecoration: 'none !important',
            },
            '.sidebarHolder': {
                height: 'max-content',
                backgroundColor: '#f0f0f0',
                position: 'relative',
                display: 'flex',
                paddingTop: '50px',
                margin: '0 20px 20px 0',
                paddingLeft: '10px',
                paddingRight: '10px',
                color: '#536889',
            },
            '.toggleSidebar': {
                position: 'absolute',
                right: '-18px',
                top: '20px',
                border: '0.1em outset #536889',
            },
            'button.toggleSidebar': {
                padding: '1px 6px',
                border: '2px solid #536889',
                justifyContent: 'center',
                display: 'flex',
            },
            '.topbar': {
                width: '100%',
                height: 'auto',
                backgroundColor: 'white',
                padding: '7px 0',
                fontFamily: '"proxima-nova", sans-serif',
            },
            '.topbarWrapper': {
                height: '100%',
                padding: '20px 40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            '.topbarRight': {
                color: '#0d2959',
            },
            '.myInMyAccount': {
                color: '#536889',
                fontSize: '48px',
                fontFamily: '"proxima-nova", sans-serif',
            },
            '.accountInMyAccount': {
                color: '#7d0029',
                fontWeight: '800',
                fontSize: '48px',
                fontFamily: '"proxima-nova", sans-serif',
            },
            '.c-site-content ul li:not(.wp-block-navigation-item):not(.wp-block-post):not(.wp-social-link):not([class^="sf-field"]):not(.c-algolia-instant-search__facet):not(.ais-Pagination-item) + li': {
                marginTop: '12px',
            },
            '.listItem': {
                cursor: 'pointer',
                position: 'relative',
                top: '-6px',
                left: '8px',
            },
            '.active': {
                color: '#f6bb46 !important',
            },
            '.listItem:hover': {
                color: '#f6bb46 !important',
            },
            '.homeWidgets': {
                display: 'flex',
                marginTop: '20px'
            },
            '.homePage-navLink': {
                color: '#536889',
                textDecoration: 'underline',
            },
            '.homePage-navLink:hover': {
                color: '#f6bb46',
            },
            '.homeLogoutBtn:hover': {
                cursor: 'pointer',
            },
            '.orderMetaData': {
                display: 'flex',
                border: '1px solid #e0e0e0',
                marginBottom: '10px',
            },
            '.serviceName': {
                flex: 1,
                textAlign: 'left',
                marginBlockStart: '1em',
                marginBlockEnd: '1em',
                marginInlineStart: '0px',
                marginInlineEnd: '0px',
                paddingInlineStart: '40px',
                borderRight: '2px solid #e0e0e0',
            },
            '.serviceMetaData': {
                textAlign: 'left',
                flex: 6,
            },
            '.billingDetailsMeta': {    //unordered list
                display: 'flex',
                flexWrap: 'wrap',
                listStyleType: 'none',
            },
            'ul.billingDetailsMeta li': { //unordered list item
                color: '#536889',
                flex: '1 0',
            },
            '.relatedSubscriptionsBlock': {
                display: 'flex',
                border: '1px solid #e0e0e0',
                margin: '5px 0 15px 0',
                padding: '15px 0 15px 0',
            },
            '.relatedSubscriptionsHeader': {
                flex: 1,
                textAlign: 'left',
                marginBlockStart: '1em',
                marginBlockEnd: '1em',
                marginInlineStart: '0px',
                marginInlineEnd: '0px',
                paddingInlineStart: '40px',
                borderRight: '2px solid #e0e0e0',
                color: '#536889'
            },
            '.relatedSubscriptionsMeta': {
                display: 'flex',
                flex: 6,
                flexDirection: 'column',
                textAlign: 'left',
            },
            '.relatedSubscriptionsMetaHeader': {        //unordered list
                display: 'flex',
                flexWrap: 'wrap',
                listStyleType: 'none',
                marginBlockStart: '0em !important',
                marginBlockEnd: '0em !important',
            },
            'ul.relatedSubscriptionsMetaHeader li': {       //unordered list item
                color: '#536889',
                flex: '1 0 20%',
            },
            '.idNamelink:hover': {
                color: '#f6bb46',
            },
            '.idNamelink': {
                fontWeight: '600',
                color: '#536889',
            },
            '.relatedSubStatusCapsule': {
                display: 'flex',
                alignItems: 'center',
            },
            '.actionButtons': {
                display: 'inline-flex',
            },
            '.statusIcon': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '.paperAddress': {
                display: 'flex',
                flexDirection: 'column',
            },
            '.order-sub-details-address': {
                width: '100%',
                boxSizing: 'border-box',
                wordWrap: 'break-word',
                display: 'flex',
                flexDirection: 'column',
                fontSize: '0.8em',
                color: '#536889',
            },
            '.metaDataList': {      //unordered list
                display: 'flex',
                flexWrap: 'wrap',
                listStyleType: 'none',
            },
            'ul.metaDataList li': {     //unordered list item
                color: '#536889',
                flex: '1 0 20%',
            },
            ".tableButtons": {
                backgroundColor: "#f6bb46",
                textShadow: "none !important",
                textTransform: "uppercase",
                letterSpacing: "1px",
                borderRadius: "2px !important",
                color: "#fff !important",
                display: "inline-block",
                position: "relative",
                border: "1px solid #ac1100",
                cursor: "pointer",
                textAlign: "center",
                textDecoration: "none",
                verticalAlign: "middle",
                boxShadow: "none !important",
                lineHeight: "0em !important",
                padding: "10px 10px !important",
                height: "inherit",
                marginLeft: "10px",
                fontFamily: "700 14px 'Open Sans', Helvetica, Arial, sans-serif",
                "&:hover": {
                    backgroundColor: "#0d2959",
                    color: "#f6bb46",
                },
            },
            ".wooButtons": {
                fontSize: "100%",
                marginRight: "8px",
                lineHeight: "1",
                cursor: "pointer",
                position: "relative",
                textDecoration: "none",
                overflow: "visible",
                padding: "0.4em 1em",
                fontWeight: "700",
                borderRadius: "3px",
                left: "auto",
                color: "#0d2959",
                backgroundColor: "#f6bb46",
                border: "0",
                display: "inline-block",
                backgroundImage: "none",
                boxShadow: "none",
                textShadow: "none",
                "&:hover": {
                    color: "#ebe9eb",
                    backgroundColor: "#0d2959",
                },
            },
            ".backButton": {
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                fontSize: "70%",
                marginBottom: "15px",
                lineHeight: "1",
                cursor: "pointer",
                position: "relative",
                textDecoration: "none",
                overflow: "visible",
                borderRadius: "3px",
                left: "auto",
                color: "#0d2959",
                backgroundColor: "#f6bb46",
                border: "0",
                padding: "6px",
                "&:hover": {
                    color: "#ebe9eb",
                    backgroundColor: "#0d2959",
                },
            },
            '.wooDetailsTable': {
                border: '1px solid rgba(0,0,0,.1)',
                margin: '0 -1px 24px 0',
                textAlign: 'left',
                width: '100%',
                borderCollapse: 'separate',
                borderRadius: '5px',
                color: '#536889',
                fontFamily: 'var(--font-family-primary)',
                fontWeight: 'var(--font-weight-regular)',
            },
            '.tableHeaderData': {
                color: 'var(--color-brand-blue)',
                fontSize: 'var(--font-size-small)',
                fontWeight: 'var(--font-weight-semi-bold)',
                letterSpacing: '2px',
                textTransform: 'uppercase',
                padding: '9px 12px',
                lineHeight: '1.5em',
            },
            '.tableHeaderDataTotal' : {
                color: 'var(--color-brand-blue)',
                fontSize: 'var(--font-size-small)',
                fontWeight: 'var(--font-weight-semi-bold)',
                letterSpacing: '2px',
                textTransform: 'uppercase',
                padding: '9px 12px',
                lineHeight: '1.5em',
                width: '300px',
            },
            '.tableBodyData': {
                borderTop: '1px solid rgba(0,0,0,.1)',
                padding: '9px 12px',
                verticalAlign: 'middle',
                lineHeight: '1.5em',
                display: 'table-cell',
            },
            '.subscriptionDetailStatus' : {
                padding: '9px 12px',
                verticalAlign: 'middle',
                lineHeight: '1.5em',
                display: 'table-cell',
            },
            '.titleHelper': {
                color: '#536889',
                fontFamily: 'var(--font-family-primary)',
                fontWeight: 'var(--font-weight-regular)',
                lineHeight: 'var(--line-height-body-desktop)',
            },
            '.wooOrderDetails': {
                marginBottom: '2em',
                color: '#536889',
                fontFamily: '"proxima-nova", sans-serif',
                fontWeight: '400',
                marginBlockStart: '1em',
            },
            '.detailMetaDataList': {        //unordered list
                listStyleType: 'none',
            },
            '.totalTableData': {
                borderTop: '1px solid rgba(0,0,0,.1)',
                alignContent: 'flex-start',
                padding: '9px 12px',
                lineHeight: '1.5em',
                display: 'flex',
            },
            '.tfootTh': {
                fontWeight: '700',
                borderTop: '1px solid rgba(0,0,0,.1)',
                padding: '9px 12px',
                lineHeight: '1.5em',
            },
            '.wooTableFootTd': {
                display: 'table-cell',
                fontWeight: '700',
                borderTop: '1px solid rgba(0,0,0,.1)',
                padding: '9px 12px',
                verticalAlign: 'middle',
                lineHeight: '1.5em',
            },
            '.orderBillingDetails': {
                fontStyle: 'normal',
                marginBottom: '0',
                border: '1px solid rgba(0,0,0,.1)',
                borderBottomWidth: '2px',
                borderRightWidth: '2px',
                textAlign: 'left',
                width: 'auto',
                borderRadius: '5px',
                padding: '6px 12px',
            },
            '.backButtonText': {
                textDecorationStyle: 'dotted',
                textDecorationLine: 'underline',
            },
            '.subIds': {
                display: "inline-flex",
                "&:hover": {
                    color: "#f6bb46",
                },
            },
            'svg.payIcon': {
                fontSize: "1.6em",
            },
            '.add-credit-card-form' : {
                display: 'flex',
            },
            '.labelHolder': {
                display: "flex",
                flexDirection: "column",
                marginTop: "0px",
                alignContent: "flex-end !important",
                marginRight: '40px',
            },
            'label.credit-card-labels': {
                margin: '15px',
                minWidth: '100px'
            },
            '.input-credit-card': {
                marginLeft: '80px',
                marginTop: '15px',
            },
            '.submit-credit-card': {
                fontSize: "100%",
                marginLeft: "9px",
                marginTop: "15px",
                marginRight: "5px",
                lineHeight: "1",
                cursor: "pointer",
                position: "relative",
                textDecoration: "none",
                overflow: "visible",
                padding: "0.618em 1em",
                fontWeight: "700",
                borderRadius: "3px",
                left: "auto",
                color: "#0d2959",
                backgroundColor: "#f6bb46",
                border: "0",
                display: "inline-block",
                backgroundImage: "none",
                boxShadow: "none",
                textShadow: "none",
                "&:hover": {
                    color: "#ebe9eb",
                    backgroundColor: "#0d2959",
                },
            },
            'input.input-credit-card':{
                padding: '8px',
                lineHeight: '1',
                backgroundColor: '#ffffff',
                border: '1px solid rgba(0,0,0,.15)',
                borderRadius: '2px',
                overflow: 'visible',
                fontFamily: 'inherit',
                fontSize: '100%',
                margin: '9px',
            },
            '.rccs__name': {
                display: 'none !important',
            },
            '.rccs': {
                marginLeft: '100px !important',
                marginTop: '0px !important',
                marginBottom: '0 !important',
            },
            'input.formikInput':{
                fontSize: '1em',
            },
            '.formFieldInput': {
                display: 'flex',
            },
            '.checkBoxContainer': {
                display: 'flex',
            },
            'form.editBillingAddressForm':{
                width: '40%',
            },
            '.internal-spinner': {
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                height: '80vh',
                width: '100vw',
                top: '150px',
                bottom: '0',
                left: '0',
                right: '0',
            },
            '.spinner':{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                position: "fixed", // Fixed position
                top: '0', // Covering the whole viewport
                left: '0',
                right: '0',
                bottom: '0',
                zIndex: '9999',
            },
            '.spinner-img': {
                minWidth: '300px',
                minHeight: '300px',
                maxWidth: '500px', /* Adjust as needed */
                maxHeight: '500px', /* Adjust as needed */
            },
            'p#modal-modal-description': {
                display: 'flex',
                justifyContent: 'space-between'
            },
            // 'label.checkBoxLabel': {
            //     fontSize: '0.9rem',
            //     display: 'flex',
            //     justifyContent: 'center',
            //     paddingTop: '10px',
            //     cursor: 'pointer',
            // },
            '.formHolder': {
                display: 'flex',
                flexDirection: 'row',
            },
            '.submitWooButton':{
                display: 'flex',
                justifyContent: 'center',
            }
        }}
    />
);
