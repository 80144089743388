import React, {useEffect, useState, useRef} from "react";
import Cards from "react-credit-cards-2";
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import "../styles/addCreditCard.css";
import {useDispatch, useSelector} from "react-redux";
import getPaymentMethods from "../../../actions/getPaymentMethods";
import addPaymentMethod from "../../../actions/addPaymentMethod";
import deletePaymentMethod from "../../../actions/deletePaymentMethod";
import changePaymentMethodForAllSubs from "../../../actions/changePaymentMethodForAllSubs";
import {useNavigate} from "react-router-dom";
import postAcceptJS from "../../../actions/postAccept";
import {ModalWorkFlow} from "./modalWorkFlow";
import MultiCheckboxModal from "./MultiCheckboxModal";
import DeleteCardFlow from "./deleteCardFlow";
import {sanitizeUrl} from "../../subscription/Subscription";

export const transformCreditCards = (creditCards) => {
    return (creditCards || [])  // Ensure creditCards is an array or default to an empty array
        .filter(card => card && card.method && card.method.gateway === "authorize_net_cim_credit_card")
        .map((card, index) => {
            if (card.actions && card.actions.delete) {
                return {
                    id: index + 1,
                    displayName: `Ending in ${card.method.last4} with expiry ${card.expires}`,
                    deleteUrl: card.actions.delete.url
                };
            }
            return null;
        })
        .filter(Boolean);  // Filter out null values
};

export function getCardType(number) {
    const cardPatterns = {
        Visa: /^4\d{12}(?:\d{3})?$/,
        MasterCard: /^(5[1-5]\d{14}|222[1-9]\d{12}|22[3-9]\d{13}|2[3-6]\d{14}|27[01]\d{13}|2720\d{12})$/,
        AmericanExpress: /^3[47]\d{13}$/,
        DinersClub: /^3(?:0[0-5]|[68]\d)\d{11}$/,
        Discover: /^6(?:011|5\d{2})\d{12}$/,
        JCB: /^(?:2131|1800|35\d{3})\d{11}$/
    };

    for (let card in cardPatterns) {
        if (cardPatterns[card].test(number)) {
            return card;
        }
    }

    return 'Credit Card';
}


function CreditCardForm(props) {
    const userId = props.id;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cardAdded =  useSelector((state) => state.paymentMethodsState.cardAdded);
    const paymentLoad =  useSelector((state) => state.paymentMethodsState.loadingAdd);
    const count = useSelector((state) => state.paymentMethodsState.count);

    const paymentMethods =  useSelector((state) => state.paymentMethodsState.paymentMethods);
    const {success,message,cc: creditCards, pp: paypalPayments} = paymentMethods;
    const site_url = process.env.REACT_APP_WP_SITE_URL;

    const customerProfileId= props.customerProfileId;
    const billingInfo = props.billingInfo;
    const changePaymentMethodURL = sanitizeUrl(props.changePaymentMethodURL);
    const match = changePaymentMethodURL.match(/\/order-pay\/(\d+)\//);
    const changePaymentID = match ? match[1] : null;
    const wcsnonce = props.wcsnonce;
    const gateway = "authorize_net_cim_credit_card";

    const [cardInfo, setCardInfo] = useState({
        cvc: "",
        expiry: "",
        focus: "",
        number: "",
    });

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [warnedAboutLimit, setWarnedAboutLimit] = useState(false);
    const [authNetCount, setAuthNetCount] = useState(false);
    const [firstDataCount, setFirstDataCount] = useState(false);
    const [updateAllSubscriptions, setUpdateAllSubscriptions] = useState(false);
    const lastFocusedInputRef = useRef(null);
    // const {authNet : authNetCount} = count;

    const handleInputFocus = () => {
        if (authNetCount >= 10 && !warnedAboutLimit) {
            setShowDeleteModal(true);
            setWarnedAboutLimit(true);  // Set the warning flag
        }
    };

    const countPaymentTypes = (paymentMethods) => {
        let firstDataCount = 0;
        let authorizeNetCount = 0;

        if (paymentMethods && paymentMethods.cc) {
            for (let entry of paymentMethods.cc) {
                switch (entry.method.gateway) {
                    case "first_data_payeezy_gateway_credit_card":
                        firstDataCount++;
                        break;
                    case "authorize_net_cim_credit_card":
                        authorizeNetCount++;
                        break;
                    default:
                        break;
                }
            }
        }

        setAuthNetCount(authorizeNetCount);
        setFirstDataCount(firstDataCount);

        return {
            firstData: firstDataCount,
            authNet: authorizeNetCount
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "expiry") {
            // Remove any non-digit characters
            let sanitizedValue = value.replace(/\D/g, '');

            // Make sure the month value is between 00 and 12
            if (sanitizedValue.length === 2) {
                const month = parseInt(sanitizedValue.substring(0, 2), 10);
                if (month < 1 || month > 12) {
                    sanitizedValue = sanitizedValue.substring(0, 1);
                }
            }

            // Limit the input length to 4 digits (MMYY)
            if (sanitizedValue.length > 4) {
                sanitizedValue = sanitizedValue.substring(0, 4);
            }

            // Add a slash after the month (MM/) if there are more than 2 digits
            if (sanitizedValue.length > 2) {
                sanitizedValue = sanitizedValue.substring(0, 2) + '/' + sanitizedValue.substring(2);
            }

            setCardInfo({ ...cardInfo, [name]: sanitizedValue });
        } else {
            setCardInfo({ ...cardInfo, [name]: value });
        }
    };

    const transformedCards = transformCreditCards(creditCards || []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await sendPaymentDataToAnet();

            setCardInfo({
                cvc: "",
                expiry: "",
                focus: "",
                number: "",
            });
        } catch (error) {
            console.error("Error while sending payment data:", error);
        }
    }

    const handleDeleteCardConfirm = async (selectedCardId) => {

        const cardToDelete = transformedCards.find(card => card.id === selectedCardId);
        const deleteURL = site_url+ cardToDelete.deleteUrl;

        if (cardToDelete) {
            try {
                await dispatch(deletePaymentMethod(userId, deleteURL, creditCards.length));
            } catch (error) {
                console.error("Error deleting the card:", error);
            }
        }
        setShowDeleteModal(false);
    };


    const sendPaymentDataToAnet = () => {
        var authData = {
            clientKey: process.env.REACT_APP_PUBLIC_CLIENT_KEY,
            apiLoginID: process.env.REACT_APP_AUTHORIZE_API_LOGIN_ID
        };

        var cardData = {
            cardNumber: cardInfo.number,
            month: cardInfo.expiry.split("/")[0], // assuming MM/YY format
            year: cardInfo.expiry.split("/")[1],
            cardCode: cardInfo.cvc
        };

        const secureData = {
            authData: authData,
            cardData: cardData
        };

        return new Promise((resolve, reject) => {
            window.Accept.dispatchData(secureData, (response) => {
                responseHandler(response);
                if (response.messages.resultCode === "Error") {
                    reject(response);
                } else {
                    resolve(response);
                }
            });
        });
    }

    function responseHandler(response) {
        if (response.messages.resultCode === "Error") {
            var i = 0;
            while (i < response.messages.message.length) {
                i = i + 1;
            }
        }

        if (response.messages.resultCode === "Ok") {
            var i = 0;
            while (i < response.messages.message.length) {
                i = i + 1;
            }

            const {dataDescriptor,dataValue} = response.opaqueData;
            const last4 = cardInfo.number.slice(-4);
            const expiry = cardInfo.expiry;
            const cardType = getCardType(cardInfo.number);
            if (updateAllSubscriptions) {

                localStorage.setItem('last4', last4);
                localStorage.setItem('expiry', expiry);
                localStorage.setItem('changedPayment','false');
                localStorage.setItem('madeDefault','false');
                const note = "added_and_updated";
                dispatch(changePaymentMethodForAllSubs(
                    userId,expiry, "", wcsnonce, "", gateway,
                    changePaymentMethodURL, changePaymentID,cardType,
                    dataValue, dataDescriptor, last4, "1",note,navigate
                ));
                return; // This will exit the function after the dispatch, preventing further execution
            }
            
            dispatch(addPaymentMethod(userId, last4, expiry, dataDescriptor, dataValue,cardType));

        }
    }

    const handleModalClose = () => {
        if (lastFocusedInputRef.current) {
            lastFocusedInputRef.current.blur();
        }
        setShowDeleteModal(false);
        setWarnedAboutLimit(false);  // Reset the warning flag
    };


    useEffect(() => {
        countPaymentTypes(paymentMethods);

        const script = document.createElement("script");
        script.src = process.env.REACT_APP_AUTHORIZE_ACCEPTJS_URL; // use the production URL in a live environment
        script.async = true;
        document.body.appendChild(script);
    }, [paymentMethods]);

    return (
        <div className="add-credit-card-form">

            <form onSubmit={handleSubmit} id="paymentForm">
                <div className={"formHolder"}>

                    <div className="labelHolder">
                        <label className="credit-card-labels">Card Number</label>
                        <label className="credit-card-labels">Expiry Date</label>
                        <label className="credit-card-labels">CVC</label>
                    </div>
                    <div className="labelHolder">
                        <input
                            type="tel"
                            name="number"
                            value={cardInfo.number}
                            placeholder="Enter Card Number"
                            onChange={(e) => {
                                handleInputChange(e);
                                if (!showDeleteModal) {  // Make sure the modal isn't already open
                                    lastFocusedInputRef.current = e.target;
                                    handleInputFocus();
                                }
                            }}
                            aria-label="Card Number"
                            onFocus={(e) => setCardInfo({ ...cardInfo, focus: e.target.name })}
                            maxLength="16"
                            className='input-credit-card'
                        />
                    {/*</div>*/}
                    {/*<div>*/}
                        <input
                            type="tel"
                            name="expiry"
                            value={cardInfo.expiry}
                            placeholder="MM/YY"
                            onChange={(e) => {
                                handleInputChange(e);
                                if (!showDeleteModal) {  // Make sure the modal isn't already open
                                    lastFocusedInputRef.current = e.target;
                                    handleInputFocus();
                                }
                            }}
                            aria-label="Expiry Date"
                            onFocus={(e) => setCardInfo({ ...cardInfo, focus: e.target.name })}
                            maxLength='5'
                            className='input-credit-card'
                        />
                    {/*</div>*/}
                    {/*<div>*/}
                        <input
                            type="tel"
                            name="cvc"
                            value={cardInfo.cvc}
                            placeholder="Enter CSC"
                            onChange={(e) => {
                                handleInputChange(e);
                                if (!showDeleteModal) {  // Make sure the modal isn't already open
                                    lastFocusedInputRef.current = e.target;
                                    handleInputFocus();
                                }
                            }}
                            aria-label="CVC"
                            onFocus={(e) => setCardInfo({ ...cardInfo, focus: e.target.name })}
                            maxLength="4"
                            className='input-credit-card'
                        />
                    </div>
                </div>
                <input type="hidden" name="dataValue" id="dataValue" />
                <input type="hidden" name="dataDescriptor" id="dataDescriptor"/>
                <div className="labelHolder">
                    <label className={"checkBoxLabel"}>
                        <input
                            type="checkbox"
                            checked={updateAllSubscriptions}
                            onChange={e => setUpdateAllSubscriptions(e.target.checked)}
                        />
                        Assign all subscriptions to this new payment method
                    </label>
                    <div className={'submitWooButton'}>
                        <button className='submit-credit-card'>Submit</button>
                    </div>
                </div>
            </form>

            <Cards
                cvc={cardInfo.cvc}
                expiry={cardInfo.expiry}
                focused={cardInfo.focus}
                name={cardInfo.name}
                number={cardInfo.number}
            />

            {showDeleteModal &&
                <DeleteCardFlow
                    cards={transformedCards}  // You'll need to fetch or pass this list of existing cards
                    onConfirm={handleDeleteCardConfirm}
                    onClose={handleModalClose}
                />
            }
        </div>
    );
}

export default CreditCardForm;
