import "./homepage.css";
// import "../../styles/style.scss";
import { NavLink} from "react-router-dom";
import HomePageFeature from "../../components/FeaturedInfo/HomePageFeature"
import AuthenticationButton from "../../components/authentication-button"
import { useAuth0 } from '@auth0/auth0-react';
import Typography from '@mui/material/Typography';

export default function HomePage(props){

    const orders = props.orders;
    const subList = props.subList;
    const userDetails = props.details;
    const { logout } = useAuth0();
    const wp_site_url = process.env.REACT_APP_WP_SITE_URL;

    const logoutButton = () => {
        sessionStorage.removeItem('switchToQueryParams');
        window.location.replace(wp_site_url+'/logout');
    }

    const welcomeHome = (userDetails) => {
        const {first_name,last_name} = userDetails;
        return userDetails.hasOwnProperty('first_name') ? (
            <div className={"HomepageContent"}>
                <Typography
                    variant="h5"
                >
                    Hello <strong>{first_name} {last_name}</strong> (not <strong>{first_name} {last_name}</strong>?
                    <b className={"homeLogoutBtn idNamelink"} onClick={logoutButton} style={{textDecorationLine:'underline'}}>{" "}Logout</b>)
                </Typography>
                <br/>
                <Typography
                    variant="body1"
                >
                    From your self service portal dashboard, you can <NavLink to={'/orders'} className={"homePage-navLink"}>view your recent orders</NavLink>,
                    <NavLink to={'/edit-address'} className={"homePage-navLink"}> manage your billing address</NavLink>, and <NavLink to={'/profile'} className={"homePage-navLink"}>view account details</NavLink>.
                </Typography>

            </div>
        ) : (
            <div hidden={true}>

            </div>
        )
    }

    return  (
        <div className={"pageView"}>
            <div className={"pageWrapper"}>
            <div className={"homeWidgets"}>
                {welcomeHome(userDetails)}
            </div>
            </div>
        </div>
    )
}